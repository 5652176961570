.section_one_wrapper {
    @apply
    w-[100%]
    flex
    items-start
    justify-center
    pt-[50px]
    mb-[200px]
    relative
    /* pl-[100px]
    mobile:px-[100px]
    xs:mb-[100px]
    sm:px-[40px]
    xs:px-[20px]
    xs:pt-0 */
}
.section_one_wrapper .section_one {
    @apply
    /* max-w-[1649px] */
    w-[100%]
    flex
    items-start
    justify-center
    /* xl:max-w-[900px]
    mobile:max-w-[900px]
    md:max-w-[700px]
    sm:max-w-[600px] */
}

.section_one_wrapper .section_one .container {
    @apply
    flex
    w-full
    items-start
    justify-between
    flex-row
    /* xl:flex-col-reverse
    xl:items-center
    xl:justify-start */
    mobile:flex-col-reverse
    mobile:items-center
    mobile:justify-start
    /* md:flex-col-reverse
    md:items-center
    md:justify-start
    sm:flex-col-reverse
    sm:items-center
    sm:justify-start */
}

.section_one_wrapper .section_one .container .items_left {
    @apply
    flex-col
    items-start
    justify-start
    xxl:w-[865px]
    mr-[-10vw]
    mobile:mr-[0]
    /* xl:mr-0 */
    xl:w-[auto]
    /* mobile:mr-0 */
    mobile:w-[auto]
    md:mr-0
    md:w-[auto]
    /* sm:mr-0 */
    sm:w-[auto]
    min-w-[50vw]
}

.section_one_wrapper .section_one .container .items_left .title {
    @apply
    flex
    items-end
    justify-start
    mb-[30px]
}

.section_one_wrapper .section_one .container .items_left .title .logo {
    @apply
    w-[5vw]
    h-[auto]
    /* xl:w-[70px] */
    /* mobile:w-[70px] */
    /* md:w-[60px] */
    /* sm:w-[50px] */
    flex
    mr-[19px]
    xs:mr-[10px]
}

.section_one_wrapper .section_one .container .items_left .title .logo img {
    @apply
    max-w-[100%]
    max-h-[100%]
    object-contain
}

.section_one_wrapper .section_one .container .items_left .title .text {
    @apply
    text-[#ffffff]
}

.section_one_wrapper .section_one .container .items_left .title .text > h1 {
    @apply
    font-[700]
    text-[5vw]
    whitespace-nowrap
    leading-[3.8vw]
    xs:leading-[30px]
    /* xl:text-[60px]
    mobile:text-[60px]
    md:text-[50px]
    sm:text-[40px] */
}

.section_one_wrapper .section_one .container .items_left .explain {
    @apply
    mb-[27px]
}
.section_one_wrapper .section_one .container .items_left .bottom_explain {
    @apply
    mb-[20px]
    text-[24px]
}
.section_one_wrapper .section_one .container .items_left .explain p {
    @apply
    text-[1.9vw]
    font-[400]
    text-[#ffffff]
    xl:text-[26px]
    mobile:text-[26px]
    md:text-[24px]
    md:leading-[31.48px]
    sm:text-[24px]
    sm:leading-[31.48px]
    xs:!text-[18px]
    xs:!leading-[26px]
}

.section_one_wrapper .section_one .container .items_left .image {
    @apply
    mb-[69px]
    w-[100%]
    max-w-[358px]
    xs:w-full
    xs:!max-w-none
    xs:!mb-[70px]
    /* xl:max-w-[250px]
    xl:mb-[30px]
    mobile:max-w-[250px]
    mobile:mb-[30px]
    md:max-w-[150px]
    md:mb-[20px] */
}

.section_one_wrapper .section_one .container .items_left .register_wrapper{
    @apply
    flex
    flex-col
    items-start
    justify-start
    mb-[138px]
    /* sm:items-center */
}

.section_one_wrapper .section_one .container .items_left .register_wrapper .counter{
    @apply
    flex
    flex-col
    items-start
    justify-start
    xs:mb-[50px]
    /* sm:items-center */
}

.section_one_wrapper .section_one .container .items_left .register_wrapper .counter .text {
    @apply
    mb-[26px]
    /* text-[#3D6514] */
    text-[#7E57C2]
    text-[18px]
    font-[400]
    xs:mb-0
}

.section_one_wrapper .section_one .container .items_left .items {
    @apply
    w-[100%]
    flex
    flex-wrap
    justify-start
    mobile:flex-wrap
    gap-x-[35px]
    gap-y-[100px]
    md:flex-col
    md:items-center
}

.section_one_wrapper .section_one .container .items_right {
    @apply
    flex
    items-center
    justify-center
    w-full
    mobile:justify-center
    mobile:mb-7
}

.section_one_wrapper .section_one .container .items_right .image {
    @apply
    w-[32vw]
    flex
    items-center
    justify-center
    relative
    xs:w-[100vw]
    xs:mb-[40px]
    /* xl:w-[700px]
    mobile:w-[700px]
    md:w-[500px]
    sm:w-[400px] */
    /* xl:mb-[20px]
    mobile:mb-[20px]
    md:mb-[10px]
    sm:mb-[10px] */
}

/* .section_one_wrapper .section_one .container .items_right .image::before {
    content: "";
    background: radial-gradient(39% 39% at 50% 50%, rgba(240, 160, 77, 0.4) 0%, rgba(29, 32, 31, 0) 83.65%);
    @apply
    absolute
    w-[2218px]
    h-[2218px]
    z-[-1]
    top-[-50%]
    left-[-50%]
} */

.section_one_wrapper .section_one .container .items_right .image img {
    @apply
    max-w-[100%]
    max-h-[100%]
    object-contain
}
/* @media screen and (max-width: 1440px) {
    .section_one_wrapper .section_one .container {
        @apply
        flex-col-reverse
        items-center
        justify-start
    }
    .section_one_wrapper .section_one .container .items_left {
        @apply
        !mr-0
    }
} */
@media screen and (max-width: 1660px) {
    /* .section_one_wrapper .section_one .container .items_left{
        @apply
        mr-[-360px]
    } */
}
@media screen and (max-width: 640px) {
    .section_one_wrapper {
        @apply
        mb-[100px]
    }
    .section_one_wrapper .section_one .container .items_right .image{
        @apply
        mb-[10px]
        w-[100%]
        max-w-[400px]
    }
    /* .section_one_wrapper .section_one {
        @apply
        max-w-[540px]
    } */
    .section_one_wrapper .section_one .container {
        @apply
        flex-col-reverse
        items-center
        justify-start
    }

    .section_one_wrapper .section_one .container .items_left .items {
        @apply
        flex-col
        items-center
    }
    /* .section_one_wrapper .section_one .container .items_left .register_wrapper .counter{
        @apply
        items-center
    } */
    .section_one_wrapper .section_one .container .items_left {
        @apply
        mr-0
        w-[auto]
    }
    .section_one_wrapper .section_one .container .items_left .title .logo {
        @apply
        w-[57px]
    }
    .section_one_wrapper .section_one .container .items_left .title .text > h1 {
        @apply
        text-[40px]
    }
    .section_one_wrapper .section_one .container .items_left .explain p {
        @apply
        leading-[31.48px]
        text-[24px]
    }
    .section_one_wrapper .section_one .container .items_left .image {
        @apply
        md:mb-[20px]
        max-w-[150px]
    }
}