.teams_wrapper {
  /* background: linear-gradient(327.72deg,#7E57C2 0%, #ffffff 99.06%); */
  @apply w-[100%]
    min-h-[263px]
    flex
    flex-col
    items-center
    justify-center
    p-[30px]
    rounded-[12px]
    xl:mr-[0]
    relative
    z-[1]
    xxl:max-w-none;
}
.teams_wrapper::before {
  content: "";
  background: linear-gradient(354.72deg, #7E57C2 0%, #ffffff 99.06%) border-box;
  border: 2px solid transparent;
  border-radius: 12px;
  content: "";
  height: 100%;
  inset: 0;
  -webkit-mask: radial-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.teams_wrapper::after {
  content: "";
  background: linear-gradient(180deg, #182430 0%, rgba(45, 58, 71, 0.7) 100%);
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border-radius: 12px;
  @apply absolute
    left-[1.5px]
    top-[1.5px];
}

.teams_wrapper:nth-child(even) {
  @apply mr-[0];
}

.teams_wrapper .teams {
  @apply w-[100%]
    flex
    items-start
    justify-start
    relative
    z-[2]
    h-full;
}
.teams_wrapper .teams .teamsWrapper{
  @apply 
  flex 
  flex-col;
  flex-grow: 1;
  align-items: flex-start
}

.teams_wrapper .teams .title {
  @apply w-[100%]
    flex
    items-center
    justify-start
    mb-[20px];
}

.teams_wrapper .teams .title h3 {
  @apply font-[600]
    text-[24px]
    text-[#ffffff];
}

.teams_wrapper .teams .items {
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  @apply w-full
    overflow-y-auto
    /* grid
    grid-cols-5
    grid-rows-3
    xl:grid-cols-4
    mobile:grid-cols-3
    mobile:grid-rows-3
    md:grid-cols-2
    md:grid-rows-4
    xs:grid-cols-1 */
    h-full;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

@media (max-width: 639px) {
  .teams_wrapper .teams .items {
    grid-row-gap: 30px;
  }
}

.teams_wrapper .teams .items .item {
  @apply flex
  gap-[20px]
  /* w-[154px] */
  /* mr-[58px]
  my-[10px] */
  justify-start
  leading-[28.32px]
  /* h-[27px] */
  text-[#ffffff]
  font-[300]
  text-[20px]
  whitespace-nowrap;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
}

.teams_wrapper .teams .items .item .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
}
.teams_wrapper .teams .items .item >p{
  @apply 
  font-[300]
  text-[20px]
 ;
}
.teams_wrapper .teams .items .item .city_name{
  @apply 
  font-[300]
  text-[16px]
  text-[#9b9b9b];
 ;
}
.teams_wrapper .teams .items .empty {
  @apply flex
    items-center
    justify-start
    gap-[5px]

    h-[37px]
;
}
.teams_wrapper .teams .items .empty::after {
  content: "";
  @apply w-[100%]
 
    h-[37px]
    border-b-[1px]
    border-b-[#ffffff]
    border-dotted
    mb-[30px];
}
.teams_wrapper .teams .items .empty::before {
  content: "";
  @apply w-[47px]
    h-[37px]
    min-w-[8px]
    mr-[10px]
    bg-[#d9d9d9]
    rounded-full;
}

@media screen and (max-width: 640px) {
  .teams_wrapper {
    @apply w-full

        h-[450px]

        justify-start;
  }
  .teams_wrapper:last-child {
    @apply !mr-[0];
  }
  .teams_wrapper .teams .items {
    @apply flex
        flex-col
        items-start
        justify-start
        overflow-y-auto;
  }
  .teams_wrapper .teams .items .item {
    @apply text-[16px]
        justify-start
     ;
  }
  .teams_wrapper .teams .items .empty {
    @apply h-[27px];
  }
  .teams_wrapper .teams .items .empty::before {
    display: none;
  }
  .teams_wrapper:nth-child(even) {
    @apply mr-[28px];
  }
  .teams_wrapper .teams .items .item::before {
    display: none;
  }
}
.teams_wrapper .teams .items .item .city_name{
  @apply 
  flex
  gap-[5px]
  items-center
  ;
}
.teams_wrapper .teams .items .item .flag{
  @apply w-[37px]
    h-[37px]
    bg-[#d9d9d9]
    flex
    items-center
    justify-center
    rounded-full;
    overflow: hidden;
}

.teams_wrapper .teams .items .item .flag img {
  @apply
  max-w-full
  max-h-full
  object-contain
}