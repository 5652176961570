.button_show_register {
  @apply w-[100%]
    flex
    md:justify-start
    items-center
    sm:justify-center;
}

.button_show_register .button {
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.6),
    11px 11px 35px rgba(255, 234, 0, 0.15);
  transition: 0.3s;
  @apply font-[inherit]
    w-[100%]
    /* max-w-[225px] */
    cursor-pointer
    px-[10px]
    py-[5px]
    h-[46px]
    border-solid
    border-[2px]
    border-[#FFEA00]
    rounded-[10px]
    bg-[#FFEA00]
    text-[#1D201F]
    text-[20px]
    font-[700]
    flex
    items-center
    justify-center;
}

.button_show_register .button:hover {
  @apply text-[#7E57C2]
    bg-transparent;
}

@media screen and (max-width: 640px) {
  .button_show_register .button {
    @apply max-w-[none];
  }
}
