.events_box {
    box-shadow: 3px 4px 7px 0px rgba(0, 0, 0, 0.60), 6px 6px 43px 0px rgba(131, 104, 1, 0.59);
    @apply
    fixed
    bottom-[40px]
    right-[40px]
    w-[48px]
    h-[48px]
    flex
    duration-300
    items-center
    justify-center
    bg-[#FFCB00]
    rounded-[15px]
    z-20
    
}
.events_box.active {
    @apply
    w-[64px]
    h-[64px]
}
.events_box .icon {
    @apply
    w-[40px]
    h-[40px]
    flex
    items-center
    justify-center
    duration-300
}
.events_box.active .icon {
    @apply
    w-full
    h-full
}
.events_box .button {
    @apply
    cursor-pointer
    w-full
    h-full
    flex
    items-center
    justify-center
    p-[10px]
}

.events_box .icon svg {
    @apply
    w-full
    h-full
}

.events_box .icon svg path {
    stroke: #000;
}

.events_box .text {
    @apply
    absolute
    right-[64px]
    duration-300
    whitespace-nowrap
    text-white
    text-[26px]
    font-[700]
    opacity-0
    invisible
}
.events_box.active .text{
    @apply
    right-[104px]
    opacity-100
    visible
}
.cover {
    backdrop-filter: blur(10px);
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.29) 100%);
    @apply
    fixed
    top-0
    left-0
    duration-300
    w-full
    h-full
    z-20
    opacity-0
    invisible
}

.cover.active {
    @apply
    visible
    opacity-100
}

.events_box .list {
    @apply
    opacity-0
    absolute
    flex
    flex-col
    items-end
    justify-start
    gap-y-0
    bottom-0
    duration-1000
    invisible
    min-w-[235px]
    right-0
    -z-[1]
    xs:justify-stretch
}
.events_box.active .list {
    @apply
    bottom-[84px]
    opacity-100
    visible
}
